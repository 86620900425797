import { zodResolver } from '@hookform/resolvers/zod';
import { validateBr } from 'js-brasil';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as z from 'zod';

import { Button } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { RecoveryPasswordModal } from '../../components/RecoveryPasswordModal';
import { ValidationCertificationModalResult } from '../../components/ValidationCertificationModalResult';
import { CertificationResponse } from '../../types/auth/CertificationResponse';
import { sanitizeCpf } from '../../utils/sanitize-cpf';
import { useGetGlobalQuery } from '../my-certifications/myCertificationsApiSlice';
import {
  useHasPlayerMutation,
  useLoginMutation,
  useValidateCertificationMutation,
} from './authApiSlice';
import { logout, selectUser, setCredentials } from './authSlice';

const SignInSchema = z.object({
  username: z.string().min(1, 'Usuário obrigatório'),
  password: z.string().min(1, { message: 'Senha obrigatória' }),
});

const ValidateCertificationSchema = z.object({
  cpf: z.custom(cpf => validateBr.cpf(cpf), {
    message: 'CPF inválido.',
  }),
});

interface isPlayer {
  value: string;
  label: string;
  openId: boolean;
}

interface HasPlayerResponse {
  data: isPlayer[];
}
type SignInForm = z.infer<typeof SignInSchema>;
type ValidateCertificationForm = z.infer<typeof ValidateCertificationSchema>;

export function SignIn() {
  const [searchParams] = useSearchParams();
  const [login] = useLoginMutation();
  const [hasPlayer] = useHasPlayerMutation();
  const [searchLoading, setSearchLoading] = useState(false);
  const [validateCertification] = useValidateCertificationMutation();
  const [step, setStep] = useState(0);
  const [selectedInstitution, setSelectedInstitution] =
    useState<isPlayer | null>(null);

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [showModal, setShowModal] = useState(false);
  const [certificationResponse, setCertificationResponse] =
    useState<CertificationResponse[]>();
  const [showModalRecoveryPassword, setShowModalRecoveryPassword] =
    useState(false);
  const { data: globalData, isLoading } = useGetGlobalQuery(
    { apiKey: undefined },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    },
  );
  const [enteredUsername, setEnteredUsername] = useState('');
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [institutionOptions, setInstitutionOptions] = useState<isPlayer[]>([]);

  const cpfForSearch = searchParams.get('cpf');

  const location = useLocation();
  const navigate = useNavigate();
  const redirectUrl = searchParams.get('updateProfile')
    ? '/profile'
    : '/my-certifications';

  useEffect(() => {
    if (user) {
      navigate(redirectUrl);
    }
  }, [user, navigate, location, redirectUrl]);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
  } = useForm<SignInForm>({
    mode: 'all',
    resolver: zodResolver(SignInSchema),
  });

  const {
    register: registerValidateCertification,
    handleSubmit: handleSubmitValidateCertification,
    control,
    formState: {
      isSubmitting: isSubmittingValidationCertification,
      isValid: isValidValidationCertification,
      errors: errorsValidationCertification,
    },
  } = useForm<ValidateCertificationForm>({
    mode: 'all',
    resolver: zodResolver(ValidateCertificationSchema),
  });

  const showErrorToast = (msg: string) => {
    toast.error(msg, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const verifyIsPlayer = async (username: string) => {
    try {
      const isValidCpf = validateBr.cpf(username);

      if (!isValidCpf) {
        throw new Error();
      }

      username = sanitizeCpf(username);
      const payerFound = (await hasPlayer({ username })) as HasPlayerResponse;
      const hasOpenId = payerFound.data.some(player => player.openId);

      if (hasOpenId) {
        navigate('/sso-callback');
      } else if (payerFound.data.length === 1) {
        setStep(step + 1);
        setSelectedInstitution(payerFound.data[0]);
      } else if (payerFound.data.length > 1) {
        setInstitutionOptions(payerFound.data);
        setIsLoadingModal(true);
      } else {
        showErrorToast('Usuário Inválido');
      }
    } catch (error) {
      showErrorToast('Usuário Inválido');
    }
  };

  const handleInstitutionSelect = (selectedValue: string) => {
    const selectedOption = institutionOptions.find(
      option => option.value === selectedValue,
    );
    setSelectedInstitution(selectedOption || null);
  };

  async function handleLogin({ username, password }: SignInForm) {
    try {
      if (!globalData?.loginEnable) return;

      const isValidCpf = validateBr.cpf(username);

      const parsedUsername = isValidCpf ? sanitizeCpf(username) : username;
      const data = await login({
        username: parsedUsername,
        password,
        apiKey: selectedInstitution ? selectedInstitution.value : undefined,
      }).unwrap();

      if (data?.token) {
        dispatch(setCredentials(data));

        navigate(redirectUrl, {
          state: { from: location },
          replace: true,
        });
      } else {
        showErrorToast('Usuário ou senha inválidos');

        dispatch(logout());
      }
    } catch (error) {
      showErrorToast('Usuário ou senha inválidos');
    }
  }

  const getCertificationByCpf = useCallback(
    async (cpf: string) => {
      setSearchLoading(true);

      try {
        const data = await validateCertification({
          cpf,
        }).unwrap();

        if (data?.length > 0) {
          setShowModal(true);
          setCertificationResponse(data);
        } else {
          toast.error('Candidato não encontrado', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } catch (error) {
        toast.error('Candidato não encontrado', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setSearchLoading(false);
      }
    },
    [validateCertification],
  );

  async function handleValidateCertification({
    cpf,
  }: ValidateCertificationForm) {
    getCertificationByCpf(cpf);
  }

  useEffect(() => {
    if (cpfForSearch) {
      getCertificationByCpf(cpfForSearch);
    }
  }, [cpfForSearch, getCertificationByCpf]);

  if (
    !isLoading &&
    window.location.href.includes('bra.assbancertifica.com.br') &&
    globalData?.currentBuild !== process.env.REACT_APP_VERSION
  ) {
    toast.warning(
      'A versão do sistema está desatualizada, por favor atualize a página.',
      {
        position: 'top-center',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        toastId: 'version',
      },
    );
  }

  if (isLoading || searchLoading) {
    return <Loading />;
  }

  return (
    <div className="mt-10 md:flex flex-row justify-center py-12 px-6 lg:px-8 container mx-auto">
      {showModal && certificationResponse && (
        <ValidationCertificationModalResult
          openModal={() => setShowModal(false)}
          data={certificationResponse}
        />
      )}

      <RecoveryPasswordModal
        show={showModalRecoveryPassword}
        cancel={() => setShowModalRecoveryPassword(false)}
      />

      <div className="md:w-1/2 md:mr-8">
        <div>
          <h1 className="text-3xl font-bold text-gray-900 border-b border-solid pb-4 border-gray-300">
            Acessar Conta Assban
          </h1>
        </div>

        <div className="mt-8 ">
          <div>
            <form
              name="signin-form"
              className="mb-0 space-y-6"
              onSubmit={handleSubmit(handleLogin)}
            >
              {step === 0 && (
                <div>
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-gray-700"
                  >
                    CPF
                  </label>
                  <div className="mt-1">
                    <input
                      className="w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-700 focus:ring-1 focus:ring-blue-700"
                      id="username"
                      {...register('username')}
                      placeholder="CPF"
                      type="text"
                      disabled={!globalData?.loginEnable}
                      required
                      onBlur={e => {
                        setEnteredUsername(e.currentTarget.value);
                      }}
                    />
                    {errors.username && (
                      <span className="text-red-500">
                        {errors.username.message}
                      </span>
                    )}
                  </div>
                </div>
              )}

              {step === 1 && (
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Senha
                  </label>
                  <div className="mt-1">
                    <input
                      className="w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-700 focus:ring-1 focus:ring-blue-700"
                      id="password"
                      {...register('password')}
                      placeholder="Senha"
                      type="password"
                      disabled={!globalData?.loginEnable}
                      autoComplete="current-password"
                    />
                    {errors.password && (
                      <span className="text-red-500">
                        {errors.password.message}
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div className="flex items-center space-x-2">
                {step === 0 && (
                  <div>
                    <Button
                      onClick={() => {
                        verifyIsPlayer(enteredUsername);
                      }}
                      disabled={!globalData?.loginEnable}
                      loading={isSubmitting}
                    >
                      Próximo
                    </Button>
                  </div>
                )}
                {step === 1 && (
                  <div>
                    <Button
                      disabled={
                        !globalData?.loginEnable && (isSubmitting || !isValid)
                      }
                      loading={isSubmitting}
                      type="submit"
                    >
                      {isSubmitting ? 'Entrando...' : 'Entrar'}
                    </Button>
                  </div>
                )}
                <div>
                  <Button
                    disabled={!globalData?.loginEnable}
                    type="button"
                    onClick={() => setShowModalRecoveryPassword(true)}
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Esqueci a senha
                  </Button>
                </div>
              </div>
            </form>
          </div>

          <div>
            {globalData?.loginMessageEnable && globalData?.loginMessage && (
              <h1 className="text-2xl font-bold text-red-500 border-b border-solid pb-4 border-gray-300">
                {globalData?.loginMessage}
              </h1>
            )}

            <span className="mt-4 w-full flex justify-center">
              v{process.env.REACT_APP_VERSION}
            </span>
          </div>
        </div>
      </div>
      <div className="md:w-1/2 mt-5 md:mt-0">
        <div>
          <div>
            <h1 className="text-3xl font-bold text-gray-900 border-b border-solid pb-4 border-gray-300">
              Consulte os profissionais Certificados
            </h1>
          </div>

          <div className="mt-8">
            <form
              name="validate-certification-form"
              className="mb-0 space-y-6"
              onSubmit={handleSubmitValidateCertification(
                handleValidateCertification,
              )}
            >
              <div>
                <label
                  htmlFor="cpf"
                  className="block text-sm font-medium text-gray-700"
                >
                  CPF
                </label>
                <div className="mt-1">
                  <Controller
                    name="cpf"
                    render={({ field }) => (
                      <InputMask
                        {...field}
                        {...registerValidateCertification('cpf')}
                        mask="999.999.999-99"
                        className="w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-700 focus:ring-1 focus:ring-blue-700 p-2"
                      />
                    )}
                    control={control}
                  />
                  {errorsValidationCertification?.cpf?.message && (
                    <span className="text-red-500">{`${errorsValidationCertification?.cpf?.message}`}</span>
                  )}
                </div>
              </div>

              <div className="flex items-center space-x-2">
                <div>
                  <Button
                    disabled={
                      isSubmittingValidationCertification ||
                      !isValidValidationCertification
                    }
                    loading={isSubmittingValidationCertification}
                    type="submit"
                  >
                    {isSubmitting ? 'Pesquisando...' : 'Pesquisar'}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <p className="mb-3 mt-8">
          Este ambiente é destinado à realização do exame de certificação para
          agentes de correspondentes nos termos das Resoluções CMN nº 3.954/2011
          e 3.959/2011.
        </p>
        <p className="mb-3">
          A Certificação tem por finalidade a aferição de capacidades,
          conhecimentos e habilidades individuais com critérios objetivos e de
          aceitação geral, aprimorando continuamente a qualidade no atendimento
          prestado aos clientes de agentes correspondentes no país, facultado a
          todos aqueles que cumpram os requisitos de acesso à certificação nos
          termos da legislação em vigor.
        </p>
        <p>
          Estar aprovado em exame de certificação é condição essencial para o
          profissional poder exercer suas atividades de forma regular. Os
          certificados emitidos pela ASSBAN-DF atestam a presença, em seus
          titulares, do conhecimento e habilidade face aos critérios técnicos
          das operações, regulamentação aplicável, o Código de Defesa do
          Consumidor, ética, ouvidoria e demais aspectos inerentes às atividades
          de relacionamento dos correspondentes com seus clientes.
        </p>
      </div>
      {isLoadingModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md shadow-md  w-1/5">
            <label
              htmlFor="institution"
              className="block text-sm font-medium text-gray-700"
            >
              Selecione a instituição
            </label>

            <select
              id="institution"
              name="institution"
              className="mt-1 block w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-700 focus:ring-1 focus:ring-blue-700 p-2"
              onChange={e => handleInstitutionSelect(e.target.value)}
            >
              <option value="" disabled selected>
                Selecione a Instituição
              </option>
              {institutionOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <div className="py-3 sm:flex sm:flex-row-reverse disabled:cursor-not-allowed">
              <Button
                onClick={() => {
                  setIsLoadingModal(false);
                  setStep(step + 1);
                }}
                type="submit"
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm disabled:cursor-not-allowed"
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
