import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as z from 'zod';

import { useFiscalValidateMutation } from '../../features/exam/certificationApiSlice';
import { Button } from '../Button';

export type FiscalAuthorizationProps = {
  certificationId: string;
  onFiscalAuthorization: (fiscalApproved: boolean) => void;
  certificationLog?: string;
};

const FiscalAuthorizationSchema = z.object({
  login: z.string(),
  password: z.string(),
  accept: z.string(),
});

type FiscalAuthorizationForm = z.infer<typeof FiscalAuthorizationSchema>;

export function FiscalAuthorization({
  onFiscalAuthorization,
  certificationLog,
}: FiscalAuthorizationProps) {
  const [validateFiscal] = useFiscalValidateMutation();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FiscalAuthorizationForm>({
    mode: 'onBlur',
    resolver: zodResolver(FiscalAuthorizationSchema),
  });

  async function handleFiscalAuthorization({
    login,
    password,
  }: FiscalAuthorizationForm) {
    try {
      const resp = await validateFiscal({
        login,
        password,
        certificationLogId: certificationLog,
      });

      if ('data' in resp && resp?.data?.status === 'approved') {
        onFiscalAuthorization(true);
      } else if ('data' in resp && resp?.data?.status) {
        let message = '';

        switch (resp?.data?.status) {
          case 'error':
            message = 'Ocorreu um erro! tente novamente mais tarde!';
            break;
          case 'fiscal_not_found':
            message = 'Fiscal não encontrado.';
            break;
          case 'login_fiscal_error':
            message = 'Usuário ou senha inválidos.';
            break;
          case 'certification_log_not_found':
            message = 'Certificação não encontrado.';
            break;
          case 'fiscal_is_player':
            message = 'O usuário não pode ser fiscal da própria prova.';
            break;
          default:
            message = 'Ocorreu um erro! tente novamente mais tarde!';
            break;
        }

        toast.error(message, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="container mx-auto mt-5 flex justify-between">
      <div className="">
        <div className="bg-blue-200 border-blue-300 border border-solid p-5 rounded-md mb-4">
          <p>Solicite ao fiscal liberação para realizar a prova.</p>
        </div>

        <form
          className="mb-0 space-y-6 w-full"
          onSubmit={handleSubmit(handleFiscalAuthorization)}
        >
          <div>
            <label
              htmlFor="login"
              className="block text-sm font-medium text-gray-700"
            >
              CPF
            </label>
            <div className="mt-1">
              <input
                className="w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-700 focus:ring-1 focus:ring-blue-700"
                id="login"
                {...register('login')}
                placeholder="Usuário"
                type="text"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Senha
            </label>
            <div className="mt-1">
              <input
                className="w-full border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-700 focus:ring-1 focus:ring-blue-700"
                id="password"
                {...register('password')}
                placeholder="Senha"
                type="password"
                autoComplete="current-password"
              />
            </div>
          </div>

          <div className="flex items-center mt-1">
            <input
              {...register('accept')}
              className="mr-2"
              id="accept"
              type="checkbox"
              value="true"
            />
            <label htmlFor="accept">
              Declaro que li e aceito os termos de responsabilidades de fiscal
              de prova
            </label>
          </div>

          <div>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="submit"
            >
              {isSubmitting ? 'Validando...' : 'Validar'}
            </Button>
          </div>
        </form>
      </div>
      <div>
        <p className="text-2xl">PASSO 2 de 3</p>
      </div>
    </div>
  );
}
