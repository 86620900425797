/* eslint-disable import/no-duplicates */
/* eslint-disable react/no-danger */
import { format, setDefaultOptions } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from '../../features/auth/authSlice';
import { useGetCertificationStyleQuery } from '../../features/my-certifications/myCertificationsApiSlice';
import { useGetUserQuery } from '../../features/profile/profileApiSlice';
import { CertificationLog } from '../../types/my-certifications/MyCertification';

import './styles.css';

setDefaultOptions({ locale: ptBR });

interface CertificateProps {
  log?: CertificationLog | null;
  clearLog: () => void;
}

export function Certificate({ log, clearLog }: CertificateProps) {
  const authenticatedUser = useSelector(selectUser);
  const { data } = useGetCertificationStyleQuery(null, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  const { data: user } = useGetUserQuery(null, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  function getCertificationNumber() {
    const isBradescoCertificate =
      authenticatedUser?.apiKey === 'assban' ||
      authenticatedUser?.apiKey === '645900d1c598e96119aea368';

    if (isBradescoCertificate) {
      return String(log?.approved_date).padStart(16, '0');
    }

    return log?._id;
  }

  const ref = useRef<HTMLDivElement>(null);

  function handleDate(created: number, year: number) {
    const time = new Date(created);
    const anotherDate = new Date(created);
    anotherDate.setFullYear(time.getFullYear() + year);

    return new Date(anotherDate).getTime();
  }

  function toCPF(valor?: string) {
    if (!valor) return '';
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  useEffect(() => {
    if (log && ref.current?.innerHTML) {
      const divContents = ref.current?.innerHTML;
      const newWindow = window.open('', '', 'width=1110, height=3500');

      if (newWindow) {
        newWindow.document.write('<html>');
        newWindow.document.write(
          '<link rel="stylesheet" type="text/css" href="styles.css"></link>',
        );
        newWindow.document.write('<body >');
        if (divContents) newWindow.document.write(divContents);
        newWindow.document.write('</body>');
        newWindow.document.write('</html>');
        newWindow.document.close();

        newWindow.print();

        clearLog();
      }
    }
  }, [clearLog, log, ref]);

  const imgData = data?.[0];

  if (!log) return null;

  return (
    <div id="printable" ref={ref}>
      <style>
        {`
          @font-face {
            font-family: 'UnBPro-Bold';
            src: url('https://funifier.s3.amazonaws.com/assban-fonts/UnBPro-Bold.woff2') format('woff2');
            font-weight: bold;
            font-style: normal;
          }

          @font-face {
            font-family: 'UnBPro';
            src: url('https://funifier.s3.amazonaws.com/assban-fonts/unb.ttf') format('truetype');
            font-weight: normal;
            font-style: normal;
          }

          #certificado-assban {
            font-family: 'UnBPro-Bold', sans-serif;
          }
          .nome-aluno {
          color: black;
          display: block;
          font-size: 21px;
          font-weight: bold;
          padding-top: 261px;
          position: absolute;
          top: 0;
          left: calc(50% - -45px);
          transform: translate(-50%, 0);
          line-height: 0;
        }

        .cpf-aluno {
          color: black;
          display: block;
          font-size: 24px;
          font-weight: bold;
          padding-top: 294px;
          position: absolute;
          top: 0;
          left: 254px;
          line-height: 0;
        }

        .data-validade {
          color: black;
          display: block;
          font-size: 15px;
          left: 0;
          padding-left: 860px;
          padding-top: 486px;
          position: absolute;
          top: 0;
          font-weight: bold;
          line-height: 0;
        }

        .titulo-curso {
          color: black;
          display: block;
          font-size: 21px;
          font-weight: bold;
          left: calc(50% - -45px);
          transform: translate(-50%, 0);
          position: absolute;
          top: 321px;
          width: 677px;
          text-align: center;
          line-height: 1;
        }

        .numero-certificado {
          color: black;
          display: block;
          font-size: 12px;
          left: 0;
          padding-left: 238px;
          padding-top: 562px;
          position: absolute;
          top: 0;
          font-weight: bold;
          line-height: 0;
        }

        .data-atual {
          color: black;
          display: block;
          font-size: 14px;
          left: 0;
          padding-left: 195px;
          padding-top: 613px;
          position: absolute;
          top: 0;
          font-weight: bold;
          line-height: 0;
        }

        .data-emissao {
          color: black;
          display: block;
          font-size: 15px;
          left: 0;
          padding-left: 547px;
          padding-top: 561px;
          position: absolute;
          top: 0;
          font-weight: bold;
          line-height: 0;
        }

        .data-validade {
          color: black;
          display: block;
          font-size: 15px;
          left: 0;
          padding-left: 414px;
          padding-top: 560px;
          position: absolute;
          top: 0;
          font-weight: bold;
          line-height: 0;
        }

        .modalidade {
          color: black;
          display: block;
          font-size: 18px;
          font-weight: bold;
          left: calc(50% - -45px);
          transform: translate(-50%, 0);
          position: absolute;
          top: 387px;
          width: 677px;
          text-align: center;
          line-height: 1;
        }

        .base-legal {
          color: black;
          display: block;
          font-size: 14px;
          left: 0;
          padding-left: 508px;
          padding-top: 481px;
          position: absolute;
          top: 0;
          font-weight: normal;
          line-height: 0;
          font-family: 'UnBPro', sans-serif;
        }

        li {
          list-style-type: none;
          padding: 0;
        }

        ul,
        ol {
          padding: 0;
        }
      `}
      </style>

      {imgData?.image?.original?.url && (
        <img
          style={{ width: '1110px', height: '675px' }}
          id="certificado"
          src={imgData.image.original.url}
          alt="imagem do certificado"
        />
      )}
      <div id="certificado-assban">
        <span className="data-emissao">
          {log.quiz_log?.finished &&
            format(new Date(log.quiz_log?.finished), 'dd/MM/yyyy')}
        </span>
        <span className="data-validade">
          {log?.quiz_log?.finished &&
            format(
              new Date(
                handleDate(
                  log?.quiz_log?.finished,
                  log.certification?.certification_validity,
                ),
              ),
              'dd/MM/yyyy',
            )}
        </span>
        <span className="nome-aluno">{user?.name},</span>
        <span className="cpf-aluno">
          {user?.extra?.cpf && toCPF(user?.extra.cpf.padStart(11, '0'))}
        </span>
        <span className="titulo-curso">{log?.certification.activity}</span>
        <span className="modalidade">{log?.certification?.modality}.</span>
        <span className="base-legal">{log?.certification?.legal_basis}</span>
        <span className="numero-certificado">{getCertificationNumber()}</span>
      </div>
      <div style={{ paddingTop: '100px' }}>
        <div
          dangerouslySetInnerHTML={{
            __html: log.certification.content,
          }}
        />
      </div>
    </div>
  );
}
